::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #eef9ff;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #3e166e;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #3e166e;
  outline: 1px solid #3e166e;
  border-radius: 5px;
}
body {
  overflow-x: hidden;
}

.mantine-Drawer-drawer {
  overflow: auto !important;
}
